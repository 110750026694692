import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-tree-organization',
  templateUrl: './tree-organization.component.html',
  styleUrls: ['./tree-organization.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TreeOrganizationComponent),
      multi: true
    }
  ]
})
export class TreeOrganizationComponent implements ControlValueAccessor, OnInit {
  @Input() nodes: any[] = [];
  @Input() multiple: boolean = false;
  @Output() selectionChanged = new EventEmitter<any[]>();
  @Input() parentNodes: any[] = [];
  @Input() dataOrigin: any[] = [];

  onChange: any = () => { };
  onTouched: any = () => { };

  constructor() { }

  ngOnInit() { }

  writeValue(obj: any[]): void {
    if (obj) {
      this.nodes = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void { }

  addParentNode(node: any, parentNodes: any[]): any[] {
    return [...parentNodes, node];
  }

  toggleExpand(node: any): void {
    node.expanded = !node.expanded;
  }

  toggleCheckbox(node: any): void {
    node.checked = !node.checked;

    if (!this.multiple) {
      this.clearSelections(this.nodes);
      this.clearParentSelection(node, this.parentNodes);
      node.checked = true;
      this.emitSelection([node]);
    } else {
      const selectedNodes = this.getSelectedNodes(this.nodes);
      console.log("node đã chọn(multiple): ", selectedNodes);
      this.emitSelection(selectedNodes);
    }
  }

  emitSelection(items: any[]): void {
    this.selectionChanged.emit(items);
    this.onChange(items);
  }

  getSelectedNodes(nodes: any[]): any[] {
    let selectedNodes: any[] = [];
    if (nodes) {
      nodes.forEach(node => {
        if (node.checked) {
          selectedNodes.push(node);
        }
        if (node.children) {
          selectedNodes = selectedNodes.concat(this.getSelectedNodes(node.children));
        }
      });
    }
    return selectedNodes;
  }

  onChildSelectionChanged(selectedNodes: any[]): void {
    this.clearParentSelection(null, null);
    const allSelectedNodes = this.getSelectedNodes(this.nodes);
    this.emitSelection(allSelectedNodes);
  }

  clearParentSelection(node: any, parentNodes: any[]): void {
    if (!parentNodes) return;

    const queue: any[] = [...parentNodes];

    while (queue.length > 0) {
      const currentNode = queue.shift();
      currentNode.checked = false;

      if (currentNode.children && currentNode.children.length > 0) {
        queue.push(...currentNode.children);
      }
    }
  }

  clearChildrenSelections(children: any[]): void {
    if (!children || children.length === 0) return;

    const queue: any[] = [...children];

    while (queue.length > 0) {
      const currentNode = queue.shift();

      currentNode.checked = false;

      if (currentNode.children && currentNode.children.length > 0) {
        queue.push(...currentNode.children);
      }
    }
  }

  clearSelections(nodes: any[]): void {
    if (!nodes) return;
    nodes.forEach(node => {
      node.checked = false;
      if (node.children) {
        this.clearSelections(node.children);
      }
    });
  }

  updateParentCheckbox(node: any): void {
    const parent = this.findParent(node);
    if (parent) {
      const allUnchecked = parent.children.every(child => !child.checked);
      const someChecked = parent.children.some(child => child.checked);
      parent.checked = someChecked;
      this.updateParentCheckbox(parent);
    }
  }

  findParent(node: any): any | null {
    if (!this.nodes) return null;
    for (const item of this.nodes) {
      if (item.children && item.children.some(child => child === node)) {
        return item;
      }
      if (item.children) {
        const parent = this.findParentRecursive(node, item.children);
        if (parent) {
          return parent;
        }
      }
    }
    return null;
  }

  findParentRecursive(node: any, children: any[]): any | null {
    if (!children) return null;
    for (const child of children) {
      if (child.children && child.children.some(c => c === node)) {
        return child;
      }
      if (child.children) {
        const parent = this.findParentRecursive(node, child.children);
        if (parent) {
          return parent;
        }
      }
    }
    return null;
  }

  trackByFn(index: number, item: any): any {
    return item.id;
  }
}
